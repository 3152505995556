<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <h1>{{ $t("Do you want to add a Dutch guarantor?") }}</h1>
          <p class="fw-medium text-gray mb-3">
            {{
              $t(
                "Is your income  not sufficient enough for a particular home? Add a guarantor to meet rental obligations. Please note the guarantor has to be Dutch."
              )
            }}
          </p>

          <router-link
            :to="guarantor ? `/user/guarantor/2/${guarantor.id}` : '/user/guarantor/2'"
            class="btn btn-primary btn-block mt-4"
          >
            {{ $t("Next step") }}
          </router-link>
          <router-link to="/user/profile" class="btn btn-outline-white btn-block mt-3">
            {{ $t("Go back to profile") }}
          </router-link>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection :img="img" />
    </template>
  </AuthLayoutContent>
</template>

<script>
// import Card from "@/components/general/Card.vue";
// import CardHeading from "@/components/general/CardHeading.vue";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    RightSection,
    AuthLayoutContent,
  },
  data() {
    return {
      img: require("../../../assets/images/guarantor/step1.svg"),
    };
  },
  computed: {
    ...mapGetters("auth", ["guarantor"]),
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__input {
    width: 40px;
    height: 45px;
    background: #ffffff;
    border: 1px solid $border-color;
    border-radius: 5px;
    @include font(1.125rem, $gray4, 700);
    margin: 0 18px;
    text-align: center;
  }

  &__input:focus-visible,
  &__input:focus {
    // border-color: $primary;
    outline: $primary auto 1px;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__label {
    padding-left: 29px;
    @include font(1.125rem, #000, 500);

    @include xs {
      padding-left: 10px;
    }
  }

  &__submitBtn {
    margin-top: 41px;
    box-shadow: 0px 4px 7px rgba(0, 129, 31, 0.45);
  }

  &__numberBtn {
    background: #ffffff;
    border: 1px solid $primary;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    color: $primary;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    padding: 0 !important;
  }
}

.step__numberBtn:hover {
  color: $primary;
}
</style>
